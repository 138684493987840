import React from 'react';
import AboutVisao from '../components/Visao';
import VideoBackground from '../components/VideoBackground';
import Logo from '../components/Logo';


const HomePage = () => (
  <div className='HomePage'>
    <div className="Logo">
      <Logo />
    </div>
    <div className='VideoBackground'>
      <VideoBackground />
    </div>
    <div className='ContentCenter About'>
     {/*  <MainBanner />*/}
    <AboutVisao />
    </div>
  </div>
);

export default HomePage;