import React, { useState } from 'react';

const ItemPortfolio = () => {
  const [formData, setFormData] = useState({
    id: '',
    title: '',
    image: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.title) {
      errors.title = "Nome obrigatório";
    }
    if (!formData.image) {
      errors.image = "Image URL is required";
    } else if (!/^https?:\/\/.+\.(jpg|jpeg|png|gif)$/.test(formData.image)) {
      errors.image = "URL Inválido";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const response = await fetch('/api/portfolio', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      alert('Sucesso!');
      setFormData({
        id: '',
        title: '',
        image: '',
      });
      setErrors({});
    } else {
      alert('Houve uma falha ao gravar.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Titulo da imagem:
        <input type="text" name="title" value={formData.title} onChange={handleChange} required />
        {errors.title && <span className="error">{errors.title}</span>}
      </label>
      <label>
        URL da imagem ex.: http://www.site.st/url.jpg :
        <input type="text" name="image" value={formData.image} onChange={handleChange} required />
        {errors.image && <span className="error">{errors.image}</span>}
      </label>
      {formData.image && !errors.image && (
        <div>
          <p>Visualizar:</p>
          <img src={formData.image} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
        </div>
      )}
      <button type="submit">Submeter</button>
      <input type="hidden" name="id" value={formData.id} />
    </form>
  );
};

export default ItemPortfolio;
