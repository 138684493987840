import React, { useEffect, useState } from 'react';

import Masonry from 'react-masonry-css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './PortfolioGallery.css';

const PortfolioGallery = () => {
  const [portfolioItems, setPortfolioItems] = useState([]);

  useEffect(() => {
    fetch('/api/portfolio')
      .then(response => response.json())
      .then(data => setPortfolioItems(data))
      .catch(error => console.error('Error fetching portfolio data:', error));
  }, []);

  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 2,
    500: 1
  };

  return (
    <div className="portfolio-gallery-container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {portfolioItems.map((item) => (
          <div key={item.id} className="portfolio-item">
            <Zoom>
              <img src={item.image} alt={item.title} />
            </Zoom>
           {/*  <h3>{item.title}</h3> */}
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default PortfolioGallery;
