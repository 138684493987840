import React from 'react';

const AboutVisao = () => {
  const intro = [
    {
      id: 0,
      text: (
        <div className="">
          Na Materializa, somos especialistas na transformação de matérias-primas, com um enfoque especial em corte e impressão. <br />Oferecemos serviços de alta qualidade em Portugal e para clientes internacionais.
        </div>
      ),
    },
  ];

  const versions = [
    {
      id: 0,
      text: (
        <>
          <div className="">
            <strong>Visão:</strong> Mais do que fornecedores, queremos ser aliados dos nossos clientes, oferecendo sempre as melhores soluções para cada situação.<br />
          </div>
          <div className="">
            <strong>Missão:</strong> Investir em tecnologia avançada e na formação de uma equipa altamente qualificada para crescer de forma sustentável.<br />
          </div>
          <div className="">
            <strong>Ética:</strong> O nosso crescimento é guiado por princípios de sustentabilidade ambiental, respeito humano, responsabilidade fiscal e boas práticas comerciais.<br />
          </div>
          <div className="">
            <strong>Responsabilidade Social Corporativa:</strong> Envolvemos os nossos clientes em cada projeto, destacando-nos como uma empresa que se preocupa com o impacto social e ambiental das suas ações.<br />
          </div>
          <div className="">
            <strong>Economia Circular:</strong> Valorizamos a economia circular, reutilizando sobras de materiais para reduzir custos e resíduos, promovendo um futuro mais sustentável.<br />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      {intro.map((introItem) => (
        <div className='Intro' key={introItem.id} style={{ marginBottom: '20px' }}>
          <h1 className="Title">Inovamos e materializamos as tuas ideias de forma sustentável.</h1>
          <div>{introItem.text}</div>
        </div>
      ))}
      {versions.map((version) => (
        <div key={version.id} style={{ paddingBottom: '5px'}}>
          <h1 className="Title">Visão, Missão e Valores</h1>
          <div className='visao'>{version.text}</div>
          <div className="footerWide">Materializa - Materializamos as tuas ideias.</div>
        </div>
      ))}
    </div>
  );
};

export default AboutVisao;
