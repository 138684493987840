import React from 'react';
import Newsletter from '../components/Newsletter';


const Footer = () => (
  <footer className="footer">
    <div className="footer-column">
      <h4>Materializa.pt</h4>
      <ul>
        <li><a href="/portfolio">Portofolio</a></li>
        <li><a href="/sobre-nos">Sobre nós</a></li>
        <li><a href="/termos-condicoes">Termos e condições</a></li>
        <li><a href="/politica-privacidade">Política de Privacidade</a></li>
        <li><a href="/contacto">Contacto</a></li>
      </ul>
    </div>
    <div className="footer-column">
      <h4>Contactos</h4>
      {/* <p>Rua António Peixoto<br />Fernão Ferro<br />Portugal</p> */}
      <p>Matériaideia - Transportes e Serviços, Unipessoal Lda</p>
      <p>Materializa é uma marca registada. Todos os direitos reservados.</p>
      <p>NIPC: 516 878 174</p>
      <p>Email: <a href="mailto:geral@materializa.pt">geral@materializa.pt</a><br />Tel. +351 96 909 64 99</p>
    </div>
    <div className="footer-column">
      <h4>Newsletter</h4>
      <Newsletter/>
    </div>
  </footer>
);

export default Footer;
