import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import VideoBackground from '../components/VideoBackground';

const QuotationPage = () => {
    const pagetitle = 'Encomendar';
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/contacto');
    };

    return (
        <div className='HomePage'>
            <div className="Logo">
                <Logo />
            </div>
            <div className="MobileTitle">
                {pagetitle}
            </div>
            <div className='VideoBackground'><VideoBackground /></div>
            
            <h2>Inovamos e materializamos as tuas ideias de forma sustentável.</h2>
            <p>A Materializa, marca registada da Matériaideia - Transportes e Serviços, Unipessoal Lda, é uma empresa inovadora que se destaca na área da transformação de matérias-primas com um enfoque especial em corte e impressão. Estamos sediados em Portugal e orgulhamo-nos de oferecer serviços de alta qualidade, tanto para empresas locais como para clientes internacionais.</p>
          
            <div className="quotation-content">  
                <div className="service-block">
                    <h3>Corte Laser</h3>
                    <p><b>Precisão:</b> Corte de precisão em aço carbono até 3mm.</p>
                    <p><b>Materiais:</b> Trabalhamos com acrílico e madeira.</p>
                    <p><b>Acabamento:</b> Soluções personalizadas com acabamentos perfeitos.</p>
                    <button onClick={handleButtonClick}>Pedir Orçamento</button>
                </div>

                <div className="service-block">
                    <h3>Impressão 3D</h3>
                    <p><b>Resina:</b> Impressão em resina para alta resolução.</p>
                    <p><b>FDM:</b> Impressão FDM para peças duráveis.</p>
                    <p><b>Prototipagem:</b> Criação de protótipos detalhados e peças finais.</p>
                    <button onClick={handleButtonClick}>Pedir Orçamento</button>
                </div>

                <div className="service-block">
                    <h3>Corte de Reclamos em Vinil</h3>
                    <p><b>Publicidade:</b> Corte de reclamos para publicidade.</p>
                    <p><b>Sinalização:</b> Sinalização personalizada.</p>
                    <p><b>Versatilidade:</b> Aplicações diversas em vinil.</p>
                    <button onClick={handleButtonClick}>Pedir Orçamento</button>
                </div>

                <div className="service-block">
                    <h3>Estampagem</h3>
                    <p><b>Variedade:</b> Estampagem em porcelanas, madeiras e têxteis.</p>
                    <p><b>Personalização:</b> Personalização única de alta qualidade.</p>
                    <p><b>Materiais:</b> Trabalhamos com diversos materiais.</p>
                    <button onClick={handleButtonClick}>Pedir Orçamento</button>
                </div>
            </div>
        </div>
    );
};

export default QuotationPage;
