import React from 'react';
import Item from './ItemPortfolio';

const portfolioItem = () => (
  <div>
    <h1>Adicionar artigo</h1>
    <h3>Página de administração.</h3>
    <p>Introduzir nome e caminho (URL) para item de portfólio.</p>
    <div>
      <Item />
    </div>
  </div>
);

export default portfolioItem;
