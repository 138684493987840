import React from 'react';
import ContactForm from '../components/ContactForm';
import Visao from '../components/Visao';

const ContactPage = () => (
  <div>
    <h1>Contacta-nos</h1>
    <p>Entra em contacto connosco. Pede-nos um orçamento!</p>
    <div>
      <ContactForm />
    </div>
  </div>
);

export default ContactPage;