import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.svg'; // Import the logo

const Navbar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    // Untoggle the navbar when the route changes
    setIsToggled(false);
  }, [location]);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="navbar-toggle" onClick={handleToggle}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={`navbar-links ${isToggled ? 'active' : ''}`}>
        <li><Link to="/" onClick={() => setIsToggled(false)}>Início</Link></li>
        <li><Link to="/sobre-nos" onClick={() => setIsToggled(false)}>Sobre Nós</Link></li>
        <li><Link to="/portfolio" onClick={() => setIsToggled(false)}>Portfolio</Link></li>
        <li><Link to="/contacto" onClick={() => setIsToggled(false)}>Contacto</Link></li>
        <li><Link to="/orcamento" onClick={() => setIsToggled(false)}>Encomendar</Link></li>

        {/* ADMIN 
        <li><Link to="/admin/portfolio" onClick={() => setIsToggled(false)}>Adicionar ao Portfolio</Link></li>*/}
      </ul>
    </nav>
  );
};

export default Navbar;
