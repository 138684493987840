import React, { useState } from 'react';


const ContactForm = () => {
  const [formData, setFormData] = useState({
    civility: '',
    firstName: '',
    lastName: '',
    email: '',
    newsletter: false,
    cellphone: '',
    source: 'contact-form',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.civility) errors.civility = "Campo obrigatório";
    if (!formData.firstName) errors.firstName = "Campo obrigatório";
    if (!formData.lastName) errors.lastName = "Campo obrigatório";
    if (!formData.email) {
      errors.email = "Por favor, preenche o Email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email inválido";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const response = await fetch('/api/save-contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      alert('Obrigado por subscrever!');
      setFormData({
        civility: '',
        firstName: '',
        lastName: '',
        email: '',
        newsletter: false,
        cellphone: '',
        source: 'contact-form',
      });
      setErrors({});
    } else {
      alert('Houve uma falha no envio, por favor contacte-nos através de geral@materializa.pt .');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        {/* Género: */}
        <select name="civility" value={formData.civility} onChange={handleChange} required>
          <option value="">Seleccionar Género</option>
          <option value="Mr">Sr.</option>
          <option value="Mrs">Sra.</option>
          <option value="Ms">Outro</option>
        </select>
        {errors.civility && <span className="error">{errors.civility}</span>}
      </label>
      <label>
        {/* Nome:*/}
         <input type="text" placeholder="Nome" name="firstName" value={formData.firstName} onChange={handleChange} required />
        {errors.firstName && <span className="error">{errors.firstName}</span>}
      </label>
      <label>
       {/*  Apelido:*/}
        <input type="text" placeholder="Apelido" name="lastName" value={formData.lastName} onChange={handleChange} required />
        {errors.lastName && <span className="error">{errors.lastName}</span>}
      </label>
      <label>
       {/*  E-mail:*/}
        <input type="email" placeholder="email@conta.pt" name="email" value={formData.email} onChange={handleChange} required />
        {errors.email && <span className="error">{errors.email}</span>}
      </label>
      <label>
      {/*   Tele. (opcional):*/}
        <input type="text" placeholder="Telefone (opcional)" name="cellphone" value={formData.cellphone} onChange={handleChange} />
      </label>
      <label>
      {/*   Tele. (opcional):*/}
        <textarea placeholder="Mensagem" name="message" className="t_message" value={formData.message} onChange={handleChange} />
      </label>
      <label>
         Subscrever Newsletter:
        <input type="checkbox" name="newsletter" checked={formData.newsletter} onChange={handleChange} />
      </label>
      <div class="spacer"></div>
      <button type="submit">Submeter</button>
    </form>
  );
};

export default ContactForm;
