import React, { useState } from 'react';


const Newsletter = () => {
  const [formData, setFormData] = useState({
    email: '',
    newsletter: true,
    source: 'newsletter-registration',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Por favor, preenche o Email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email inválido";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const response = await fetch('/api/newsletter-registration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      alert('Obrigado por subscrever!');
      setFormData({
        email: '',
        newsletter: false,
      });
      setErrors({});
    } else {
      alert('Houve uma falha no envio, por favor contacte-nos através de geral@materializa.pt .');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    {/* <div><label htmlFor="newsletter">Seubscrever newsletter:</label></div> */}
      <div><input type="email" name="email" value={formData.email} onChange={handleChange} required />{errors.email && <span className="error">{errors.email}</span>}
      <button type="submit">Subscrever</button></div>
      <input type="hidden" name="newsletter" value="false" />
    </form>
  );
};

export default Newsletter;
