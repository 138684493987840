import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import Gallery from './pages/Gallery';
import ContactPage from './pages/ContactPage';
import QuotationPage from './pages/QuotationPage';
import Portfolio from './admin/addPortfolio';
import './App.css'; // Add this line to import global styles
import './components/global.css'; // Add this line to import global styles
import './components/Navbar.css'; // Add this line to import global styles
import './components/Footer.css'; // Add this line to import global styles

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sobre-nos" element={<AboutUsPage />} />
            <Route path="/portfolio" element={<Gallery />} />
            <Route path="/contacto" element={<ContactPage />} />
            <Route path="/orcamento" element={<QuotationPage />} />

            {/* admin */}
            <Route path="/admin/portfolio" element={<Portfolio />} />

          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
