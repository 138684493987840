import React from 'react';
import PortfolioGallery from '../components/PortfolioGallery';

const Gallery = () => (
  <div>
    <PortfolioGallery />
  </div>
);

export default Gallery;
