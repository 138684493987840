import React from 'react';
import VideoBackground from '../components/VideoBackground';
import AboutVisao from '../components/Visao';
import Logo from '../components/Logo';

const AboutUsPage = () => {
  const pagetitle = 'Sobre Nós';

  return (
    <div className='HomePage'>
          <div className="Logo">
            <Logo />
          </div>
          <div className="MobileTitle">
            {pagetitle}
          </div>
          <div className='VideoBackground'><VideoBackground /></div>
          <div style={{ padding: '20px 100px' }}>
            <h2>Inovamos e materializamos as tuas ideias de forma sustentável.</h2>

            <p>A Materializa, marca registada da Matériaideia - Transportes e Serviços, Unipessoal Lda, é uma empresa inovadora que se destaca na área da transformação de matérias-primas com um enfoque especial em corte e impressão. Estamos sediados em Portugal e orgulhamo-nos de oferecer serviços de alta qualidade, tanto para empresas locais como para clientes internacionais.</p>

            <h4>Os nossos serviços incluem:</h4>

            <p><b>Corte Laser:</b> Realizamos corte laser de precisão em aço carbono até um máximo de 3mm, bem como em acrílico e madeira, garantindo acabamentos perfeitos e soluções personalizadas para cada projeto.</p>
            <p><b>Impressão 3D:</b> Disponibilizamos serviços de impressão 3D tanto em resina como em FDM, permitindo a criação de protótipos detalhados e peças finais com alta resolução e durabilidade.</p>
            <p><b>Corte de Reclamos em Vinil:</b> Especializamo-nos no corte de reclamos em vinil para diversas aplicações, desde publicidade até sinalização personalizada.</p>
            <p><b>Estampagem:</b> Oferecemos serviços de estampagem em variados materiais como porcelanas, madeiras, têxteis, entre outros, garantindo uma personalização única e de alta qualidade para os seus produtos.</p>
            <p>Na Materializa, contamos com uma equipa de profissionais altamente especializados, com formação contínua e experiência comprovada no setor. Utilizamos as mais avançadas tecnologias e equipamentos, assegurando que os materiais são trabalhados com a máxima precisão e sem danos.</p>

            <p>Estamos empenhados em oferecer soluções eficazes, económicas e adaptadas às necessidades específicas de cada cliente. Caso necessite de mais informações sobre os nossos serviços ou de uma consulta para discutir o seu projeto, não hesite em contactar-nos. Os nossos colaboradores estão disponíveis para prestar toda a assistência necessária e encontrar a melhor solução para si.</p>

            <p><b>Materializa - Materializamos as tuas ideias.</b></p>
        </div>
        <div className='ContentCenter About'>
        {/*  <MainBanner />*/}
          <AboutVisao />
        </div>
    </div>
  );
};

export default AboutUsPage;
